.v-card {
  grid-template-rows: auto 1fr;
}
.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-table {
  height: 68%;
}

.v-card-table .Table__tr-heading {
  @apply border-t-2 border-b-2;
}
.card-header {
  @apply grid items-center justify-between mb-5;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  @apply grid grid-cols-1 justify-between items-center gap-4;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 292px;
}

@screen md {
  .v-card-table {
    height: calc(100% - 63px);
  }
  .card-header-section1 {
    @apply grid-cols-auto1fr;
  }
}

@media (max-width: 1280px) {
  .v-card-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .small-card-first {
    grid-column: 1/3;
  }
  .small-card-last {
    grid-column: 3/5;
  }
}
@media (max-width: 906px) {
  .small-card-first,
  .small-card-last {
    grid-column: 1/-1;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* .map-card {
    grid-column: 1/-1;
  } */
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}

.table-header-title-black {
  text-align: left;
  font: normal normal 600 22px/24px Noto Sans;
  letter-spacing: 0px;
  color: #57585a;
  opacity: 1;
}
.table-content-1-report {
  font: normal normal bold 18px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #6f7072;
}
.table-value-1-report {
  text-align: left;
  font: normal normal bold 30px/26px Noto Sans !important;
  letter-spacing: 0px;
  color: #3b3b3b;
}
@media (max-width: 1280px) {
  .large-card {
    grid-column: 1/-1 !important;
  }
  .small-card {
  }
}
