.v-auth {
  background: white;
  @apply grid h-screen;
}

.v-login-card {
  margin: auto;
  border-radius: 0;
  box-shadow: unset;
  @apply w-full;
}

.v-auth-separator {
  @apply my-5 flex items-center;
}
.v-auth-separator::after,
.v-auth-separator::before {
  content: '';
  @apply w-full h-px bg-textGrey;
}

@screen md {
  .v-login-card {
    box-shadow: unset;
    max-width: 400px;
    @apply w-full relative rounded-3xl p-8 shadow;
  }
  .v-auth {
    background: url('../../assets/images/auth-background.png') no-repeat center;
    background-size: cover;
  }
}
.error {
  font-size: 12px;
  color: red;
  text-align: center;
  height: 20px;
}
