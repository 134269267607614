.v-card {
  grid-template-rows: auto 1fr;
}
.v-compare-expand {
  grid-column-end: -1;
  grid-row: 1 / 3;
}

.rate-is-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #74a343;
}

.rate-is-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f4a335;
}

.v-card-layout {
  @apply py-5 grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 gap-6;
  grid-template-rows: repeat(3, 420px);
}

/* .v-card-layout--has-expansion {
  grid-template-rows: repeat(6, 420px);
} */
/* @media(max-width:1284px){

} */

.v-card-table {
  height: 68%;
}

.v-card-table .Table__tr-heading {
  @apply border-t-2 border-b-2;
}
.card-header {
  @apply grid items-center justify-between mb-5;
  grid-template-columns: 1fr auto;
}

.card-header-section1 {
  @apply grid grid-cols-1 justify-between items-center gap-4;
}

.compare-graph {
  min-width: 0;
  min-height: 0;
}

.compare-graph-minimal svg {
  height: 292px;
}

@screen md {
  .v-card-table {
    height: calc(100% - 63px);
  }
  .card-header-section1 {
    @apply grid-cols-auto1fr;
  }
}

.css-b62m3t-container {
  max-width: 300px;
}

.bar-chart > div > div > svg > g > g:nth-child(n + 4) > text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  fill: white !important;
  text-transform: capitalize;
  font-size: 18px;
}

.table-text {
  font: normal normal normal 1rem/16px Noto Sans;
  letter-spacing: 0px;
  color: #6f7072;
  opacity: 1;
}
@media (max-width: 1280px) {
  .container {
    max-width: 100% !important;
  }
}
